import React, { Component } from "react";
import {
  Box,
  InputGroup,
  InputLeftAddon,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  SimpleGrid
} from "@chakra-ui/react";
import IntPhoneInput from '../../components/ad/IntlPhoneNumber'

import axios from "axios";

import { injectIntl, FormattedMessage } from 'react-intl'

class Step9Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user_email: null,
      user_email_confirmation: null,
      user_name: null,
      user_phone: null
    }
  }

  isTrustedUser() {
    const { user_email } = this.state;
    var _this = this;
    axios.post(process.env.GATSBY_API_URL + "user/email/", { email: user_email })
      .then(function (response) {
        _this.props.handleChange('isTrustedUser', response.data)
        _this.props.handleChange('user_email', user_email)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  render() {
    return (
      <FormControl>
        <FormLabel htmlFor="price">
          <FormattedMessage id="form.step9.name.and.contact" />
          {/* Nom, raison sociale & contacts */}
        </FormLabel>

        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: "1rem", lg: "1rem" }}>
          <Box>
            <Input
              size="md"
              placeholder={this.props.intl.formatMessage({ id: 'main.name' })}
              name="user_name"
              onBlur={(e) => this.props.handleChange('user_name', e.target.value)}
            />
          </Box>
          <Box>
            <IntPhoneInput updatePhoneNumber={(value) => { this.props.handleChange('user_phone', value) }} />
            {/* <InputGroup>
              <InputLeftAddon w="70px" children={this.props.intl.formatMessage({ id:'main.phone'})} />
              <Input
                name="user_phone"
                type="tel"
                required
                roundedLeft="0"
                placeholder={ this.props.intl.formatMessage({ id:'form.step9.phone.and.prefix' })}
                onBlur={(e) => this.props.handleChange('user_phone', e.target.value)}
              />
            </InputGroup> */}
          </Box>
          <Box>
            <InputGroup>
              <InputLeftAddon w="70px" children="Email" />
              <Input
                name="user_email"
                type="email"
                required
                roundedLeft="0"
                placeholder={this.props.intl.formatMessage({ id: 'form.step9.email.address' })}
                onBlur={(e) => {
                  this.props.handleChange('user_email', e.target.value)
                  this.setState({ 'user_email': e.target.value })
                  this.isTrustedUser();
                }}
                onChange={(e) => {
                  this.props.handleChange('user_email', e.target.value)
                  this.setState({ 'user_email': e.target.value })
                  this.isTrustedUser();
                }}
              />
            </InputGroup>
          </Box>
          <Box mt={[4, 0]}>
            <InputGroup>
              <InputLeftAddon w="70px" children="Email" />
              <Input
                name="user_email_confirmation"
                type="email"
                required
                roundedLeft="0"
                placeholder={this.props.intl.formatMessage({ id: 'form.step9.email.address.confirmation' })}
                onBlur={(e) => {
                  this.props.handleChange('user_email_confirmation', e.target.value)
                  this.setState({ 'user_email_confirmation': e.target.value })
                  this.isTrustedUser();
                }}
                onChange={(e) => {
                  this.props.handleChange('user_email_confirmation', e.target.value)
                  this.setState({ 'user_email_confirmation': e.target.value })
                  this.isTrustedUser();
                }}
              />
            </InputGroup>
          </Box>
        </SimpleGrid>
        <FormHelperText
          id="email-helper-text"
        >
          <FormattedMessage id="form.step9.notice" />
          {/* Vos coordonneés ne seront pas visibles publiquement. On vous enverra le contact des personnes intéressées par email. Vous pouvez les indiquer sans crainte. */}
        </FormHelperText>
      </FormControl>
    );
  }
}

export default injectIntl(Step9Contact)