import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Box, Button, Flex, Text, Grid, Heading, List, ListItem, Stack } from '@chakra-ui/react'
import AdCardPortraitLarge from "../components/ad/PortraitLarge";
import LayoutFunnel from "../components/layouts/layoutFunnel.js"
import withLocation from "../utils/withLocation";
import Step9Contact from "../components/Form/Step9Contact";
import Seo from '../components/Seo/';
import axios from 'axios'
import { FormattedMessage } from 'react-intl';

const contentful = require("contentful");

const client = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE,
    environment: "master",
    accessToken: process.env.GATSBY_CONTENTFUL_TOKEN
});

const AdPreviewTemplate = (props) => {
    const [accept, setAccept] = useState(false)
    const [ad, setAd] = useState(false)
    const [model, setModel] = useState(false)
    const [contact, setContact] = useState(false)
    const [isPublished, setPublished] = useState(false)

    useEffect(() => {
        if (!ad) {
            axios.get(`${process.env.GATSBY_API_URL}annonces/${props.search.id}`)
                .then(function (response) {
                    // handle success
                    console.log(response);
                    setAd(response.data)
                    getModelDetails(response.data.refModel)
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        }
    });

    const getModelDetails = (modelID) => {
        client.getEntry(modelID,
            { locale: process.env.GATSBY_LANG }
        )
            .then(function (entry) {
                setModel(entry.fields)
                console.log('response', entry)

            })
    }

    const publish = () => {
        axios.put(`${process.env.GATSBY_API_URL}annonces/publish-preview/`, { id: props.search.id, contact: contact, lang: process.env.GATSBY_LANG })
            .then(function (response) {
                // handle success
                console.log(response);
                setPublished(true)

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });

    }

    const convertLang = (ad) => {
        if (process.env.GATSBY_LANG === 'en') {
            let convertedAd = ad;
            convertedAd['content'] = ad['description_en']
            return convertedAd
        }
        return ad
    }

    const title = {
        "fr": "Publiez votre annonce sur Wanaboat, on a tout fait pour vous"
        ,
        "en": "Publish your ad on Wanaboat, we've done everything for you."
    }
    const description = {
        "fr": "On a trouvé votre annonce et on vous propose de la mettre gratuitement sur Wanaboat.fr"
        ,
        "en": "We found your ad and we offer to put it for free on iWannaboat"
    };

    return (
        <LayoutFunnel lang={process.env.GATSBY_LANG}>
            <Seo
                title={
                    title[process.env.GATSBY_LANG]
                }
                description={description[process.env.GATSBY_LANG]

                }
                slug={''}
            />
            <Box
                minH='100%'
            >
                {ad ?
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        minH='100vh'
                        // maxW={{ xd}}
                        p={{ xl: '2rem' }}
                    // overflow='hidden'
                    >

                        <Grid
                            borderStyle={{ xl: 'solid' }}
                            borderWidth={{ xl: '1px' }}
                            borderColor='blue.100'
                            background="linear-gradient(180deg, #F1FAFF 0%, #E1F5FF 100%), #C4C4C4"
                            boxShadow={{ xxl: 'sm' }}
                            borderRadius={{ lg: "10px" }}
                            gap='2rem'
                            templateColumns={{ base: '100%', lg: '600px 1fr', xl: '700px 450px', xxl: '700px 600px' }}>
                            {!accept ?
                                <Flex alignItems="center" h='100%' p={{ base: '1rem', lg: '2rem', xl: '3rem' }} w={'100%'} >
                                    <Stack spacing='1.5rem'>
                                        <Heading color="blue.900" fontSize="42px">
                                            <FormattedMessage id="ad.preview.title1" />
                                            {/* Bonjour Capitaine, */}
                                        </Heading>
                                        <Heading color="blue.700" fontSize="24px">
                                            <FormattedMessage id="ad.preview.title2" values={{ modelName: model.name }} />

                                            {/* On a repéré votre joli {model.name ? model.name : 'bateau'} à vendre, et voici ce qu'on vous propose : */}
                                        </Heading>
                                        <Text fontSize="19px">
                                            <FormattedMessage id="ad.preview.notice1" />
                                            {/* Wanaboat.fr est un site gratuit et spécialisé dans les petites annonces nautiques depuis 2007. Le site est simple et super sécurisé. */}
                                        </Text>
                                        <Text>
                                            <FormattedMessage id="ad.preview.notice2" values={{ modelName: model.name }} />
                                            {/* Des personnes recherchent actuellement un <strong>{model.name ? model.name : 'bateau'}</strong> comme le vôtre. */}
                                        </Text>
                                        <List color="gray.700">
                                            <ListItem>→ <FormattedMessage id="ad.preview.arg1" /> </ListItem>
                                            <ListItem>→ <FormattedMessage id="ad.preview.arg2" />
                                                {/* C'est gratuit, il n'y a aucun engagement particulier */}
                                            </ListItem>
                                            {/* <ListItem>→ Quand il est vendu, vous pourrez me donner un pourboire (au choix)</ListItem> */}
                                            <ListItem>→ <FormattedMessage id="ad.preview.arg3" />
                                                {/* Vous n'avez qu'à cliquer sur "oui" et compléter 2 infos */}
                                            </ListItem>
                                        </List>
                                        <Stack isInline alignItems="center" wrap={{ base: 'wrap', lg: 'nowrap' }}>
                                            <Button mb={{ base: '1rem', lg: '0' }} w={{ base: '100%', lg: 'auto' }} size="lg" colorScheme="green" onClick={setAccept}>
                                                <FormattedMessage id="ad.preview.button.yes.label" />
                                            </Button>
                                            <Button
                                                w={{ base: '100%', lg: 'auto' }}
                                                onClick={() => { navigate('/') }}
                                                variant="outline"
                                                colorScheme='blue'
                                            >
                                                <FormattedMessage id="ad.preview.button.no.label" />
                                            </Button>
                                        </Stack>
                                        <Text fontSize="15px" color='blue.900'>
                                            <FormattedMessage id="ad.preview.notice3" />
                                        </Text>
                                    </Stack>

                                    <Text>

                                    </Text>
                                </Flex>
                                : !isPublished ?
                                    <Flex alignItems="center" h='100%' p={{ base: '1rem', lg: '2rem', xl: '3rem' }} >
                                        <Stack spacing='1.5rem'>
                                            <Heading color="blue.900" fontSize="42px">
                                                <FormattedMessage id="ad.preview.great.lets.go" />

                                                {/* Super, allons-y ! */}
                                            </Heading>
                                            <Heading color="blue.700" fontSize="24px">
                                                <FormattedMessage id="ad.preview.notice4" />&nbsp;:
                                            </Heading>
                                            <Stack
                                                spacing='2rem'
                                                as="form"
                                                background={{ lg: 'white' }}
                                                p={{ lg: '1rem ' }}
                                            >
                                                <Box>
                                                    <Step9Contact
                                                        handleChange={
                                                            (name, value) => {
                                                                setContact({ ...contact, [name]: value })
                                                                console.log(name, value)
                                                            }
                                                        }
                                                    // setContact(name,value)( { [ } )}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Button
                                                        colorScheme="green"
                                                        disabled={
                                                            (!contact.user_email || !contact.user_phone || !contact.user_name || !contact.user_email_confirmation)
                                                            || (contact.user_email != contact.user_email_confirmation)
                                                        }
                                                        onClick={publish}
                                                    >
                                                        <FormattedMessage id="form.step11.publish.your.ad" />
                                                    </Button>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </Flex>
                                    :
                                    <Flex alignItems="center" h='100%' p={{ base: '1rem', lg: '2rem', xl: '3rem' }}>
                                        <Stack spacing='1.5rem'>
                                            <Heading color="blue.900" fontSize="42px">
                                                <FormattedMessage id="ad.preview.its.ok" /> 👍
                                            </Heading>
                                            <Heading color="blue.700" fontSize="24px">
                                                <FormattedMessage id="ad.preview.notice5" />
                                                {/* Votre annonce sera en ligne dans quelques instants. Puis je vous envoie les détails par email. */}
                                            </Heading>
                                            <Box>
                                                <Button
                                                    colorScheme="green"
                                                    onClick={() => { navigate('/') }}
                                                >
                                                    <FormattedMessage id="ad.preview.button.back.to.homepage" />
                                                </Button>
                                            </Box>


                                        </Stack>

                                    </Flex>
                            }
                            <Box
                                mr={{ xxl: '-3rem' }}
                                mb={{ xxl: '-3rem' }}
                                mt={{ xxl: '-3rem' }}
                            >
                                <AdCardPortraitLarge
                                    // contactOpen={() => { setContactOpen(!isContactOpen) }}
                                    ad={convertLang(ad)}
                                    id={ad.id}
                                    closeButtonLabel="Retour"
                                    context="main-website-ad"
                                    hideFooter={true}
                                    limiteHeight={true}
                                />
                            </Box>
                        </Grid>


                    </Flex>
                    : <Box>Loading</Box>}
            </Box>
        </LayoutFunnel>
    )

}

export default withLocation(AdPreviewTemplate)